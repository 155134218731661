export const SET_CURRENT_SONG = 'player/SET_CURRENT_SONG';
export const SET_FULL_SCREEN = 'player/SET_FULL_SCREEN';
export const SET_PLAYING_STATE = 'player/SET_PLAYING_STATE';
export const SET_SEQUECE_PLAYLIST = 'player/SET_SEQUECE_PLAYLIST';
export const SET_PLAYLIST = 'player/SET_PLAYLIST';
export const SET_PLAY_MODE = 'player/SET_PLAY_MODE';
export const SET_CURRENT_INDEX = 'player/SET_CURRENT_INDEX';
export const SET_SHOW_PLAYLIST = 'player/SET_SHOW_PLAYLIST';
export const INSERT_SONG = 'player/INSERT_SONG';
export const DELETE_SONG = 'player/DELETE_SONG';
export const CHANGE_SPEED = 'player/CHANGE_SPEED';